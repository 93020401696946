import React from 'react'
import tw, { styled } from 'twin.macro'
import { ImageType, StyleType } from '../utils/prop-types'
import Image from './image'

const FullCoverImage = ({ style, image, imageMobile }) => {
  const ParallaxComponent = styled.div`
    background-image: url(${image?.fluid?.src});
    ${tw`h-full`};
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1023px) {
      background-image: url(${imageMobile?.fluid?.src});
      ${tw`w-full`};
    }
  `
  return (
    <div css={[tw`h-full-image-mobile lg:h-full-image-default`, style]}>
      <ParallaxComponent />
    </div>
  )
}

FullCoverImage.propTypes = {
  style: StyleType,
  image: ImageType,
  imageMobile: ImageType,
}

export default FullCoverImage
