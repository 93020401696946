import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import ReactPlayer from 'react-player/file'
import { ReactComponent as Close } from '../images/icon-close.svg'
import tw, { css, styled, theme } from 'twin.macro'
import { ReactComponent as Play } from '../images/icon-play.svg'

const PlayerPlayIcon = styled(Play)`
  ${tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 lg:scale-100`}
  path:first-of-type {
    transition: fill 0.3s ease-in-out;
    fill: ${(props) => (props.hover ? theme`colors.accent.500` : theme`colors.transparent`)};
  }
  path {
    transition: fill 0.3s ease-in-out;
    fill: ${(props) => (props.hover ? theme`colors.primary.500` : theme`colors.accent.500`)};
  }
  &.is-hovered {
    path:first-of-type {
      transition: fill 0.3s ease-in-out;
      fill: ${theme`colors.accent.500`};
    }
    path {
      transition: fill 0.3s ease-in-out;
      fill: ${theme`colors.accent.500`};
    }
  }
`

const VideoPlayer = ({ streamingUrl, thumbnailUrl, ...props }) => {
  const playerRef = useRef(null)
  const [hover, setHover] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, [playerRef])

  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, [playerRef])

  const onThumbnailClick = useCallback(() => {
    setIsModalOpen(true)
    setIsPlaying(true)
  }, [])

  const closeVideoModal = (e) => {
    e.preventDefault()
    setIsModalOpen(false)
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (isModalOpen) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = ''
    }
  }, [isModalOpen])

  return (
    <>
      <div
        css={css`
          ${isModalOpen ? tw`static` : tw`relative`}
          padding-top: 56.25%; /* 16:9 ratio */
          .react-player__preview {
            transition: box-shadow 0.3s ease-in-out;
            box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
            &:hover {
              box-shadow: none;
            }
          }
        `}
      >
        {thumbnailUrl && (
          <div
            css={[tw`absolute inset-0 cursor-pointer`]}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onThumbnailClick}
            aria-hidden="true"
          >
            <ReactPlayer
              url={thumbnailUrl}
              width="100%"
              height="100%"
              playing
              loop
              muted
              playsinline
            />
            <div
              css={[
                css`
                  ${tw`absolute top-0 left-0 w-full h-full`}
                  transition: box-shadow 0.3s ease-in-out;
                  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
                `,
                hover && tw`shadow-none`,
              ]}
            />
            <PlayerPlayIcon hover={hover} />
          </div>
        )}
        <div
          css={[
            tw`absolute top-0 left-0 opacity-0 pointer-events-none
              transition-opacity duration-300 ease-in-out`,
            (isModalOpen || !thumbnailUrl) && tw`fixed opacity-100 bg-primary-600 pointer-events-auto h-full w-full z-30`,
          ]}
        >
          {isModalOpen && <div
            css={[
              tw`flex content-center flex-wrap h-full w-10/12 m-auto`,
            ]}
          >
            <button
              type="button"
              css={tw`absolute top-0 right-0 cursor-pointer bg-accent-500 h-12 w-12`}
              onClick={closeVideoModal}
            >
              <div 
                css={[
                  tw`flex content-center flex-wrap h-full w-full`,
                  tw`lg:(transition-transform duration-300 ease-in-out) lg:hover:(transform rotate-90)`
                ]}>
                <Close
                  css={tw`m-auto`}
                />
              </div>
            </button>
            <div css={tw`w-auto h-auto m-auto`}>
              <ReactPlayer
                ref={playerRef}
                url={streamingUrl}
                width="auto"
                height="auto"
                playing={isPlaying}
                onPlay={onPlay}
                onPause={onPause}
                controls
                {...props}
              />
            </div>
          </div>}
        </div>
      </div>
    </>
  )
}

VideoPlayer.propTypes = {
  streamingUrl: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  props: PropTypes.array,
}

export default VideoPlayer
